<template>
  <el-dialog custom-class="join-desc-dialog"
             :visible.sync="visible"
             :append-to-body="true"
             :close-on-click-modal="false"
             @close="handleClose"
             @open="handleOpen"
             :width="isMobilePlatform?'90%':'1200px'">
    <h3>参赛特别声明</h3>
    <div class="desc-content" :class="{'mobile-desc-content':isMobilePlatform}" ref="joinDescContent">
      <p>
        1. 本次中兴捧月“星匠师”巧匠精英挑战赛的赛事日程具体安排以正式通知为准，本次大赛及赛事日程的最终解释权由主办方中兴通讯股份有限公司所有。
      </p>
      <p>
        2. 所有参赛者所参与的理论考试以及实操考试所提交的作品或答案不得违反中华人民共和国法律法规，不得含有反对国家或政府（包括其他国家或地区）、种族和宗教及身份歧视、诬蔑民族传统文化、泄露国家或商业机密、侵犯他人隐私、侮辱或诽谤他人人格、歧视残疾人的内容，如有违反上述规定，参赛者将被取消参赛资格，相关责任由参赛者本人承担。
      </p>
      <p>
        3. 参赛者同意：主办方有权在本次比赛及相关活动中，公布和使用参赛作品、参赛者姓名和其他必要信息。
      </p>
      <p>
        4. 参赛者同意：主办方有权对所有参赛作品以复制、发行、展览、放映、信息网络传播、汇编等方式进行使用，无需支付任何报酬。
      </p>
      <p>
        <b>
          5. 获奖参赛者同意：大赛所有获奖参赛作品的全部知识产权自获奖名单公布之日起归属主办方中兴通讯股份有限公司所有，未经主办方同意，获奖参赛者不得以任何方式使用、转让获奖参赛作品，并且主办方有权对获奖参赛作品进行进一步创作、修改、开发、使用及由主办方自行发表。        </b>
      </p>
      <p>6. 如发现参赛作品不符合本大赛要求的，主办方有权取消参赛及获奖资格，如参赛者已领取奖项者，主办方有权追回原奖项，如给主办方造成损失，主办方有权要求参赛者赔偿损失。</p>
      <p>7. 所有参赛者应充分理解本特别声明之内容，并承诺接受本特别声明的约束。</p>
    </div>
    <div class="bottom" v-if="showBottom">
      <el-button type="primary" @click="handleAgree" :disabled="timeValue>0">同 意<span
        class="time" v-if="timeValue>0">({{timeValue}}秒)</span>
      </el-button>
      <el-button @click="handleClose">取消</el-button>
    </div>

  </el-dialog>

</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: '',
    props: {
      value: {
        type: Boolean,
        default: false
      },
      showBottom: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        timeValue: 10,
        descTimer: null
      }
    },
    computed: {
      ...mapState(['isMobilePlatform']),
      visible: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val)
        }
      }
    },
    components: {},

    destroyed() {
      this.clearReadDescTimer()
    },
    methods: {
      handleOpen() {
        this.timeValue = 10;
        this.descTimer = null;
        if (this.showBottom) {
          this.readDescTimer();
        }
      },
      handleClose() {
        this.visible = false;
        this.clearReadDescTimer()
      },
      readDescTimer(seconds = this.timeValue) {
        this.clearReadDescTimer()
        this.timeValue = seconds
        this.descTimer = setInterval(() => {
          this.timeValue--
          if (this.timeValue === 0) {
            this.clearReadDescTimer()
          }
        }, 1000)
      },
      clearReadDescTimer() {
        if (this.descTimer) {
          clearInterval(this.descTimer)
        }
      },
      handleAgree() {
        let scrollDom = this.$refs.joinDescContent;
        // console.log("scrollDom.scrollTop", scrollDom.scrollTop);
        // console.log("scrollDom.clientHeight", scrollDom.clientHeight);
        // console.log("scrollDom.scrollHeight", scrollDom.scrollHeight)
        let nums = Math.ceil(scrollDom.scrollTop + scrollDom.clientHeight) - Math.ceil(scrollDom.scrollHeight)
        if (Math.abs(nums) < 20) {
          this.$emit('on-agree')
        } else {
          this.$message.error("请滚动阅读完隐私协议");
        }

      }
    }
  }
</script>

<style lang="scss">
  .join-desc-dialog {
    margin-top: 2vh !important;

    h3 {
      text-align: center;
    }
    .desc-content {
      height: 700px;
      overflow-y: auto;

      p {
        color: #222222;
      }
    }

    .mobile-desc-content{
      height:60vh;
    }

    .bottom {
      margin-top: 20px;
      text-align: center;
      .time {
        color: #0084FF;
      }
    }

  }
</style>